import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from './config';
import './UserRegistrations.css';

const UserRegistrations = () => {
  const [registrations, setRegistrations] = useState([]);
  const [activeRegistrations, setActiveRegistrations] = useState([]);
  const [pastRegistrations, setPastRegistrations] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_BASE_URL}/api/user/registrations`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const currentDate = new Date();
        const active = [];
        const past = [];

        response.data.forEach((registration) => {
          const eventDate = new Date(registration.date);
          if (eventDate >= currentDate) {
            active.push(registration);
          } else {
            past.push(registration);
          }
        });

        setActiveRegistrations(active);
        setPastRegistrations(past);
        setRegistrations(response.data);
      } catch (err) {
        setError('Error fetching registrations');
        console.error('Error fetching registrations:', err);
      }
    };

    fetchRegistrations();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="table-container">
      <h1>Your Event Registrations</h1>

      {registrations.length === 0 ? (
        <p>You have not registered for any events yet.</p>
      ) : (
        <>
          <section>
            <h2>Active Registrations</h2>
            {activeRegistrations.length === 0 ? (
              <p>No active registrations.</p>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th>Event Title</th>
                    <th>Event Name</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody>
                  {activeRegistrations.map((registration) => (
                    <tr key={registration._id}>
                      <td data-label="Event Title">{registration.title}</td>
                      <td data-label="Event Name">{registration.event.title}</td>
                      <td data-label="Date">{new Date(registration.date).toLocaleDateString()}</td>
                      <td data-label="Time">{registration.time}</td>
                      <td data-label="Location">{registration.location}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </section>

          <section>
            <h2>Past Registrations</h2>
            {pastRegistrations.length === 0 ? (
              <p>No past registrations.</p>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th>Event Title</th>
                    <th>Event Name</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody>
                  {pastRegistrations.map((registration) => (
                    <tr key={registration._id}>
                      <td data-label="Event Title">{registration.title}</td>
                      <td data-label="Event Name">{registration.event.title}</td>
                      <td data-label="Date">{new Date(registration.date).toLocaleDateString()}</td>
                      <td data-label="Time">{registration.time}</td>
                      <td data-label="Location">{registration.location}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </section>
        </>
      )}
    </div>
  );
};

export default UserRegistrations;
