import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './bookSummary.css';

const BookingSummary = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    // Access the booking data from location state
    const booking = location.state?.booking;

    if (!booking) {
        return (
            <div className="container-bs">
                <h2>Booking Summary</h2>
                <p>No booking details found. Please book a class first.</p>
                <button onClick={() => navigate('/')} className="btn-bs">Go to Home</button>
            </div>
        );
    }

    const { clientDetails, schedule } = booking;

    return (
        <div className="container-bs">
            <h2 className="heading-bs">Booking Summary</h2>
            <div className="details-bs">
                <h3>Client Information</h3>
                <p><strong>Client Name:</strong> {clientDetails?.name}</p>
                <p><strong>Email:</strong> {clientDetails?.email}</p>
                <p><strong>Phone:</strong> {clientDetails?.phone}</p>
                {clientDetails?.message && (
                    <p><strong>Message:</strong> {clientDetails.message}</p>
                )}
                {clientDetails?.smsReminder && (
                    <p><strong>SMS Reminder:</strong> Enabled</p>
                )}

                <h3>Booking Details</h3>
                <p><strong>Service:</strong> {schedule?.service?.service_name || schedule?.service}</p>
                <p><strong>Instructor:</strong> {schedule?.instructor?.name || schedule?.instructor}</p>
                <p>
                    <strong>Time:</strong> {schedule?.start_time} - {schedule?.end_time} {schedule?.timezone}
                </p>
                <p><strong>Date:</strong> {schedule?.date ? new Date(schedule.date).toLocaleDateString() : 'N/A'}</p>
            </div>
            
            <div className="cta-bs">
                <button onClick={() => navigate('/')} className="btn-bs">Go to Home</button>
                <button onClick={() => navigate('/Schedule')} className="btn-bs">Book Another Class</button>
            </div>
        </div>
    );
};

export default BookingSummary;