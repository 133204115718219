import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AccountPage.css';
import { API_BASE_URL } from './config';
import Navbar from './Navbar';
import Footer from './Footer';

const MyAccount = () => {
  const [activeTab, setActiveTab] = useState('details');
  const [bookings, setBookings] = useState([]);
  const [registrations, setRegistrations] = useState([]);
  const [activeRegistrations, setActiveRegistrations] = useState([]);
  const [pastRegistrations, setPastRegistrations] = useState([]);
  const [userbookings, setUserBookings] = useState([]);
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    birthDate: '',
    phoneNumber: '',
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [settings, setSettings] = useState({
    receiveNewsletters: false,
    deactivateAccount: false,
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  // Fetch user details on component mount

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/api/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserDetails(response.data);
      setError('');
    } catch (err) {
      handleSessionError(err);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_BASE_URL}/api/user/registrations`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const currentDate = new Date();
        const active = [];
        const past = [];

        response.data.forEach((registration) => {
          const eventDate = new Date(registration.date);
          if (eventDate >= currentDate) {
            active.push(registration);
          } else {
            past.push(registration);
          }
        });

        setActiveRegistrations(active);
        setPastRegistrations(past);
        setRegistrations(response.data);
      } catch (err) {
        setError('Error fetching registrations');
        console.error('Error fetching registrations:', err);
      }
    };

    if (activeTab === 'registrations') {
      fetchRegistrations();
  }
  }, [activeTab]);

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.put(
        `${API_BASE_URL}/api/profile`,
        {
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          email: userDetails.email,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSuccess('Profile updated successfully');
      setError('');
      setActiveTab('details');
    } catch (err) {
      handleSessionError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setError('New passwords do not match');
      return;
    }
    if (passwordData.newPassword.length < 6) {
      setError('Password must be at least 6 characters long');
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.put(
        `${API_BASE_URL}/api/change-password`,
        {
          currentPassword: passwordData.currentPassword,
          newPassword: passwordData.newPassword,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSuccess('Password changed successfully');
      setError('');
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
      setActiveTab('details');
    } catch (err) {
      if (err.response?.status === 400) {
        setError('Current password is incorrect');
      } else {
        handleSessionError(err);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (!window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.delete(`${API_BASE_URL}/api/delete-account`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSuccess('Account deleted successfully');
      logoutUser(); // Ensures cleanup and redirection
    } catch (err) {
      handleSessionError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.post(`${API_BASE_URL}/api/logout`, null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSuccess('Logged out successfully');
    } catch (err) {
      console.error('Logout failed, clearing session...');
    } finally {
      logoutUser();
    }
  };

  const logoutUser = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  const handleSessionError = (err) => {
    if (err.response?.status === 401) {
      setError('Session expired. Please login again');
      logoutUser();
    } else {
      setError(err.response?.data?.error || 'An error occurred');
    }
  };


  useEffect(() => {
    if (activeTab === 'bookings') {
        fetchBookings();
    }
}, [activeTab]);

const fetchBookings = async () => {
  try {
    const token = localStorage.getItem('token'); // Ensure token matches backend expectation
    const response = await axios.get(`${API_BASE_URL}/api/user/book-class`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setBookings(response.data);
    setError('');
  } catch (error) {
    console.error('Error fetching bookings:', error);
    setError('Failed to fetch bookings.');
  }
};

const activeBookings = bookings.filter(
  (booking) => booking.status === 'pending' || booking.status === 'approve'
);

const bookingHistory = bookings.filter(
  (booking) => booking.status === 'decline' || booking.status === 'complete'
);

// Helper function to render a table
const renderTable = (title, bookings) => (
  <div className="table-section">
    <h4>{title}</h4>
    {bookings.length > 0 ? (
      <table className="bookings-table">
        <thead>
          <tr>
            <th>Service</th>
            <th>Instructor</th>
            <th>Date</th>
            <th>Time</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {bookings.map((booking) => (
            <tr key={booking._id}>
              <td>{booking.schedule.service.service_name}</td>
              <td>{booking.schedule.instructor.name}</td>
              <td>{new Date(booking.schedule.date).toLocaleDateString()}</td>
              <td>
                {booking.schedule.start_time} - {booking.schedule.end_time}{' '}
                {booking.schedule.timezone}
              </td>
              <td>{booking.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <p className="no-data">No bookings found in this category.</p>
    )}
  </div>
);



useEffect(() => {
  const fetchUserBookings = async () => {
      try {
          const token = localStorage.getItem('token'); // Assuming the JWT is stored in localStorage
          const response = await axios.get(`${API_BASE_URL}/api/user/bookings`, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          });
          setUserBookings(response.data);
          setLoading(false);
      } catch (err) {
          setError(err.response?.data?.error || 'Failed to fetch bookings');
          setLoading(false);
      }
  };

  if (activeTab === 'userbookings') {
    fetchUserBookings();
}
}, [activeTab]);

if (loading) return <p style={{ textAlign: 'center', fontSize: '1.2rem', color: '#555' }}>Loading...</p>;
if (error) return <p style={{ textAlign: 'center', fontSize: '1.2rem', color: 'red' }}>Error: {error}</p>;

const activeUserBookings = userbookings.filter(
  (booking) => booking.status === 'pending' || booking.status === 'approve'
);
const bookingUserHistory = userbookings.filter(
  (booking) => booking.status === 'decline' || booking.status === 'complete'
);

const renderUserTable = (title, bookings) => (
  <div>
      <h4 style={styles.subHeader}>{title}</h4>
      {bookings.length > 0 ? (
          <table style={styles.table}>
              <thead>
                  <tr>
                      {/* <th style={styles.th}>Booking ID</th> */}
                      <th style={styles.th}>Service</th>
                      <th style={styles.th}>Date</th>
                      <th style={styles.th}>Time</th>
                      <th style={styles.th}>Status</th>
                      {/* <th style={styles.th}>Client Name</th>
                      <th style={styles.th}>Contact</th> */}
                  </tr>
              </thead>
              <tbody>
                  {bookings.map((booking) => (
                      <tr key={booking._id} style={styles.row}>
                          {/* <td style={styles.td}>{booking._id}</td> */}
                          <td style={styles.td}>{booking.service.service_name}</td>
                          <td style={styles.td}>{new Date(booking.date).toLocaleDateString()}</td>
                          <td style={styles.td}>{booking.time}</td>
                          <td style={styles.td}>{booking.status}</td>
                          {/* <td style={styles.td}>{booking.client_name}</td>
                          <td style={styles.td}>
                              {booking.client_phone} <br /> {booking.client_email}
                          </td> */}
                      </tr>
                  ))}
              </tbody>
          </table>
      ) : (
          <p style={styles.noData}>No bookings available in this category.</p>
      )}
  </div>
);




  return (
    <>
    <Navbar />
    
    <div className="account-container">
      
      {/* Sidebar Navigation */}
      <div className="sidebar">
        <h2>My Account</h2>
        <ul>
          <li 
            className={activeTab === 'details' ? 'active' : ''} 
            onClick={() => setActiveTab('details')}
          >
            My details
          </li>
          <li 
            className={activeTab === 'edit' ? 'active' : ''} 
            onClick={() => setActiveTab('edit')}
          >
            Edit Profile
          </li>
          <li
            className={activeTab === 'userbookings' ? 'active' : ''}
            onClick={() => setActiveTab('userbookings')}
          >
           Classes Bookings 
          </li>
          <li
            className={activeTab === 'bookings' ? 'active' : ''}
            onClick={() => setActiveTab('bookings')}
          >
           Schedule Bookings 
          </li>
          <li
            className={activeTab === 'registrations' ? 'active' : ''}
            onClick={() => setActiveTab('registrations')}
          >
           Event Registrations 
          </li>
          <li 
            className={activeTab === 'password' ? 'active' : ''} 
            onClick={() => setActiveTab('password')}
          >
            Change Password
          </li>
          <li 
            className={activeTab === 'settings' ? 'active' : ''} 
            onClick={() => setActiveTab('settings')}
          >
            Account settings
          </li>
        </ul>
      </div>

      {/* Main Content Area */}
      <div className="details-container">
        {/* Error and Success Messages */}
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}
        {loading && <div className="loading">Loading...</div>}

        {/* Breadcrumbs */}
        <div className="breadcrumbs">
          Homepage / My Account {activeTab !== 'details' && `/ ${activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}`}
        </div>

        {/* Content Sections */}
        {activeTab === 'details' && (
          <>
            <h2>My details</h2>
            <div className="form-section">
              <h3>Personal Information</h3>
              <p>View and manage your personal information.</p>
              <form>
                <div className="form-group">
                  <label>First Name</label>
                  <input type="text" value={userDetails.firstName} disabled />
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input type="text" value={userDetails.lastName} disabled />
                </div>
              </form>
            </div>
            <div className="form-section">
              <h3>E-mail address</h3>
              <p>Assertively utilize adaptive customer service for future-proof platforms. Completely drive optimal markets.</p>
              <div className="form-group">
                <label>E-mail address</label>
                <input type="email" value={userDetails.email} placeholder="email@example.pl" disabled />
              </div>
            </div>
          </>
        )}

        {activeTab === 'edit' && (
          <>
            <h2>Edit Profile</h2>
            <form onSubmit={handleUpdateProfile}>
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  value={userDetails.firstName}
                  onChange={(e) => setUserDetails({...userDetails, firstName: e.target.value})}
                  required
                />
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  value={userDetails.lastName}
                  onChange={(e) => setUserDetails({...userDetails, lastName: e.target.value})}
                  required
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  value={userDetails.email}
                  onChange={(e) => setUserDetails({...userDetails, email: e.target.value})}
                  required
                />
              </div>
              <button className="save-button" type="submit" disabled={loading}>
                {loading ? 'Saving...' : 'Save Changes'}
              </button>
            </form>
          </>
        )}

        {activeTab === 'password' && (
          <>
            <h2>Change Password</h2>
            <form onSubmit={handleChangePassword}>
              <div className="form-group">
                <label>Current Password</label>
                <input
                  type="password"
                  value={passwordData.currentPassword}
                  onChange={(e) => setPasswordData({...passwordData, currentPassword: e.target.value})}
                  required
                />
              </div>
              <div className="form-group">
                <label>New Password</label>
                <input
                  type="password"
                  value={passwordData.newPassword}
                  onChange={(e) => setPasswordData({...passwordData, newPassword: e.target.value})}
                  required
                />
              </div>
              <div className="form-group">
                <label>Confirm New Password</label>
                <input
                  type="password"
                  value={passwordData.confirmPassword}
                  onChange={(e) => setPasswordData({...passwordData, confirmPassword: e.target.value})}
                  required
                />
              </div>
              <button className="save-button" type="submit" disabled={loading}>
                {loading ? 'Changing Password...' : 'Change Password'}
              </button>
            </form>
          </>
        )}

        {activeTab === 'settings' && (
          <>
            <h2>Account Settings</h2>
            <form>
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={settings.receiveNewsletters}
                    onChange={(e) => setSettings({...settings, receiveNewsletters: e.target.checked})}
                  />
                  Receive Newsletters
                </label>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="logout-button"
                  onClick={handleLogout}
                  disabled={loading}
                >
                  Logout
                </button>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="delete-account-button"
                  onClick={handleDeleteAccount}
                  disabled={loading}
                >
                  Delete Account
                </button>
              </div>
            </form>
          </>
        )}

        {activeTab === 'bookings' && (
              <div className="bookings-container">
              {error ? (
                <p className="error">{error}</p>
              ) : (
                <>
                  {renderTable('Active Bookings', activeBookings)}
                  {renderTable('Booking History', bookingHistory)}
                </>
              )}
            </div>
   
          

        )}

{activeTab === 'userbookings' && (
        <div style={styles.container}>
        <h2 style={styles.header}>Your Bookings</h2>
        {renderUserTable('Active Bookings', activeUserBookings)}
        {renderUserTable('Booking History', bookingUserHistory)}
    </div>
   
          

        )}

{activeTab === 'registrations' && (
    <div className="table-container">
    <h1>Your Event Registrations</h1>

    {registrations.length === 0 ? (
      <p>You have not registered for any events yet.</p>
    ) : (
      <>
        <section>
          <h3>Active Registrations</h3>
          {activeRegistrations.length === 0 ? (
            <p>No active registrations.</p>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  {/* <th>Event Title</th> */}
                  <th>Event Name</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {activeRegistrations.map((registration) => (
                  <tr key={registration._id}>
                    {/* <td data-label="Event Title">{registration.title}</td> */}
                    <td data-label="Event Name">{registration.event.title}</td>
                    <td data-label="Date">{new Date(registration.date).toLocaleDateString()}</td>
                    <td data-label="Time">{registration.time}</td>
                    <td data-label="Location">{registration.location}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </section>

        <section>
          <h3>Past Registrations</h3>
          {pastRegistrations.length === 0 ? (
            <p>No past registrations.</p>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  {/* <th>Event Title</th> */}
                  <th>Event Name</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {pastRegistrations.map((registration) => (
                  <tr key={registration._id}>
                    {/* <td data-label="Event Title">{registration.title}</td> */}
                    <td data-label="Event Name">{registration.event.title}</td>
                    <td data-label="Date">{new Date(registration.date).toLocaleDateString()}</td>
                    <td data-label="Time">{registration.time}</td>
                    <td data-label="Location">{registration.location}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </section>
      </>
    )}
  </div>
        )}
      </div>
    </div>
    <Footer />
    </>
  );
};

const styles = {
  container: {
    margin: '20px auto',
    padding: '20px',
    maxWidth: '1200px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    '@media (max-width: 768px)': {
      margin: '10px',
      padding: '15px',
      borderRadius: '6px',
    },
    '@media (max-width: 480px)': {
      margin: '5px',
      padding: '10px',
    }
  },
  header: {
    textAlign: 'center',
    fontSize: '1.5rem',
    marginBottom: '20px',
    color: '#333',
    '@media (max-width: 768px)': {
      fontSize: '1.3rem',
      marginBottom: '15px',
    },
    '@media (max-width: 480px)': {
      fontSize: '1.2rem',
      marginBottom: '12px',
    }
  },
  subHeader: {
    fontSize: '1rem',
    margin: '20px 0',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
      margin: '15px 0',
    },
    '@media (max-width: 480px)': {
      fontSize: '0.85rem',
      margin: '12px 0',
    }
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
    '@media (max-width: 768px)': {
      display: 'block',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    }
  },
  tableWrapper: {
    '@media (max-width: 768px)': {
      overflowX: 'auto',
      WebkitOverflowScrolling: 'touch',
      marginBottom: '15px',
    }
  },
  th: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px',
    textAlign: 'left',
    borderBottom: '2px solid #ddd',
    '@media (max-width: 768px)': {
      padding: '8px',
      fontSize: '0.9rem',
      minWidth: '120px', // Ensures columns don't get too narrow
    },
    '@media (max-width: 480px)': {
      padding: '6px',
      fontSize: '0.85rem',
    }
  },
  td: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
    color: '#555',
    '@media (max-width: 768px)': {
      padding: '8px',
      fontSize: '0.9rem',
      minWidth: '120px',
    },
    '@media (max-width: 480px)': {
      padding: '6px',
      fontSize: '0.85rem',
    }
  },
  row: {
    backgroundColor: '#fff',
    '@media (max-width: 768px)': {
      '&:hover': {
        backgroundColor: '#f5f5f5',
      }
    }
  },
  noData: {
    textAlign: 'center',
    color: '#777',
    fontStyle: 'italic',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
      padding: '15px 0',
    },
    '@media (max-width: 480px)': {
      fontSize: '0.85rem',
      padding: '12px 0',
    }
  },
  // Optional: Add styles for mobile-specific table view
  mobileTableCell: {
    '@media (max-width: 480px)': {
      display: 'flex',
      flexDirection: 'column',
      padding: '8px',
      borderBottom: '1px solid #ddd',
    }
  },
  mobileLabel: {
    '@media (max-width: 480px)': {
      fontWeight: 'bold',
      marginBottom: '4px',
      color: '#333',
    }
  }
};

export default MyAccount;