import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from './config';

const UserBookingsTable = () => {
    const [userbookings, setUserBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserBookings = async () => {
            try {
                const token = localStorage.getItem('token'); // Assuming the JWT is stored in localStorage
                const response = await axios.get(`${API_BASE_URL}/api/user/bookings`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUserBookings(response.data);
                setLoading(false);
            } catch (err) {
                setError(err.response?.data?.error || 'Failed to fetch bookings');
                setLoading(false);
            }
        };

        fetchUserBookings();
    }, []);

    if (loading) return <p style={{ textAlign: 'center', fontSize: '1.2rem', color: '#555' }}>Loading...</p>;
    if (error) return <p style={{ textAlign: 'center', fontSize: '1.2rem', color: 'red' }}>Error: {error}</p>;

    const activeUserBookings = userbookings.filter(
        (booking) => booking.status === 'pending' || booking.status === 'approve'
    );
    const bookingUserHistory = userbookings.filter(
        (booking) => booking.status === 'decline' || booking.status === 'complete'
    );

    const renderTable = (title, bookings) => (
        <div>
            <h3 style={styles.subHeader}>{title}</h3>
            {bookings.length > 0 ? (
                <table style={styles.table}>
                    <thead>
                        <tr>
                            {/* <th style={styles.th}>Booking ID</th> */}
                            <th style={styles.th}>Service</th>
                            <th style={styles.th}>Date</th>
                            <th style={styles.th}>Time</th>
                            <th style={styles.th}>Status</th>
                            {/* <th style={styles.th}>Client Name</th>
                            <th style={styles.th}>Contact</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {bookings.map((booking) => (
                            <tr key={booking._id} style={styles.row}>
                                {/* <td style={styles.td}>{booking._id}</td> */}
                                <td style={styles.td}>{booking.service.service_name}</td>
                                <td style={styles.td}>{new Date(booking.date).toLocaleDateString()}</td>
                                <td style={styles.td}>{booking.time}</td>
                                <td style={styles.td}>{booking.status}</td>
                                {/* <td style={styles.td}>{booking.client_name}</td>
                                <td style={styles.td}>
                                    {booking.client_phone} <br /> {booking.client_email}
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p style={styles.noData}>No bookings available in this category.</p>
            )}
        </div>
    );

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>Your Bookings</h2>
            {renderTable('Active Bookings', activeUserBookings)}
            {renderTable('Booking History', bookingUserHistory)}
        </div>
    );
};

const styles = {
    container: {
        margin: '20px auto',
        padding: '20px',
        maxWidth: '1200px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    },
    header: {
        textAlign: 'center',
        fontSize: '1.5rem',
        marginBottom: '20px',
        color: '#333',
    },
    subHeader: {
        fontSize: '1.2rem',
        margin: '20px 0',
        color: '#555',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginBottom: '20px',
    },
    th: {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '10px',
        textAlign: 'left',
        borderBottom: '2px solid #ddd',
    },
    td: {
        padding: '10px',
        borderBottom: '1px solid #ddd',
        color: '#555',
    },
    row: {
        backgroundColor: '#fff',
    },
    noData: {
        textAlign: 'center',
        color: '#777',
        fontStyle: 'italic',
    },
};

export default UserBookingsTable;