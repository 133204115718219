import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Button } from 'antd';

const BookingSummary = () => {
  const location = useLocation();
  const { state } = location;
  const { booking } = state || {};

  if (!booking) {
    return (
      <div style={styles.container}>
        <h2 style={styles.header}>No Booking Details Available</h2>
        <Link to="/">
          <Button style={styles.button}>Go to Home</Button>
        </Link>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Booking Summary</h2>
      <div style={styles.card}>
        <h3 style={styles.subHeader}>Booking Details</h3>
        <p><strong>Service Name:</strong> {booking.service}</p>
        <p><strong>Date:</strong> {booking.date}</p>
        <p><strong>Time:</strong> {booking.time}</p>
        <p><strong>Client Name:</strong> {booking.client_name}</p>
        <p><strong>Email:</strong> {booking.client_email}</p>
        <p><strong>Phone:</strong> {booking.client_phone}</p>
        {/* <p><strong>Message:</strong> {booking.message}</p> */}
      </div>

      <Link to="/">
        <Button style={styles.button}>Back to Home</Button>
      </Link>
    </div>
  );
};

const styles = {
  container: {
    margin: '20px auto',
    padding: '20px',
    maxWidth: '600px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
  header: {
    fontSize: '1.5rem',
    marginBottom: '20px',
    color: '#333',
  },
  card: {
    textAlign: 'left',
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
  },
  subHeader: {
    fontSize: '1.2rem',
    marginBottom: '10px',
    color: '#555',
  },
  button: {
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default BookingSummary;
