import React, { useState, useEffect } from 'react';
import './BookClasses.css';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from './config';

const BookClasses = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isDropdownActive, setIsDropdownActive] = useState(false);
    const [clientDetails, setClientDetails] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        smsReminder: false
    });
    const [validationErrors, setValidationErrors] = useState({});
    const [schedule, setSchedule] = useState(location.state?.schedule || {
        service: '',
        instructor: '',
        date: '',
        start_time: '',
        end_time: '',
        timezone: ''
    });

    // Check authentication on component mount
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/LoginPage', {
                state: {
                    redirectTo: location.pathname,
                    bookingData: { clientDetails, schedule }
                }
            });
        }
    }, []);

    const validateForm = () => {
        const errors = {};
        if (!clientDetails.name.trim()) errors.name = 'Name is required';
        if (!clientDetails.email.trim()) errors.email = 'Email is required';
        if (!clientDetails.phone.trim()) errors.phone = 'Phone number is required';
        
        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (clientDetails.email && !emailRegex.test(clientDetails.email)) {
            errors.email = 'Please enter a valid email';
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/LoginPage', {
                state: {
                    redirectTo: location.pathname,
                    bookingData: { clientDetails, schedule }
                }
            });
            return;
        }

        if (!validateForm()) {
            return;
        }

        try {
            const response = await axios.post(
                `${API_BASE_URL}/api/user/book-class`,
                { clientDetails, schedule },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            // Clear saved form data
            localStorage.removeItem('bookingFormData');

            navigate('/BookSummary', {
                state: {
                    booking: {
                        ...response.data.booking,
                        clientDetails,
                        schedule
                    }
                }
            });
        } catch (error) {
            console.error('Error:', error);
            
            if (error.response?.status === 401) {
                // Token expired or invalid
                localStorage.removeItem('token');
                navigate('/LoginPage', {
                    state: {
                        redirectTo: location.pathname,
                        bookingData: { clientDetails, schedule }
                    }
                });
                return;
            }
            
            alert(error.response?.data?.message || 'Failed to send booking request. Please try again.');
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setClientDetails(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));

        // Save form data to localStorage
        const updatedDetails = {
            ...clientDetails,
            [name]: type === 'checkbox' ? checked : value
        };
        localStorage.setItem('bookingFormData', JSON.stringify({
            clientDetails: updatedDetails,
            schedule
        }));

        // Clear validation error for the changed field
        setValidationErrors(prev => ({
            ...prev,
            [name]: ''
        }));
    };

    // Load saved form data on mount
    useEffect(() => {
        const savedData = localStorage.getItem('bookingFormData');
        if (savedData) {
            const { clientDetails: savedDetails, schedule: savedSchedule } = JSON.parse(savedData);
            setClientDetails(savedDetails);
            setSchedule(savedSchedule);
        }
    }, []);

    const toggleDropdown = () => {
        setIsDropdownActive(!isDropdownActive);
    };

    return (
        <div className="containerbb">
            <div className="p-6-cb bg-card-cb text-card-foreground-cb">
                <a href="/Schedule" className="text-muted-foreground-cb mb-4-cb inline-block-cb">&lt; Back</a>
                <h2 className="text-xl-cb font-semibold-cb mb-4-cb">Client Details</h2>
                <hr />
                
                <form className="space-y-4-cb" onSubmit={handleFormSubmit}>
                    <div className="flex-cb space-x-4-cb">
                        <div className="flex-1-cb">
                            <label className="block-cb text-sm-cb font-medium-cb">Name *</label>
                            <input
                                type="text"
                                name="name"
                                value={clientDetails.name}
                                onChange={handleChange}
                                className={`mt-1-cb block-cb w-full-cb border-cb p-2-cb ${
                                    validationErrors.name ? 'border-red-500' : 'border-border-cb'
                                }`}
                            />
                            {validationErrors.name && (
                                <p className="text-red-500 text-xs">{validationErrors.name}</p>
                            )}
                        </div>
                        
                        <div className="flex-1-cb">
                            <label className="block-cb text-sm-cb font-medium-cb">Email *</label>
                            <input
                                type="email"
                                name="email"
                                value={clientDetails.email}
                                onChange={handleChange}
                                className={`mt-1-cb block-cb w-full-cb border-cb p-2-cb ${
                                    validationErrors.email ? 'border-red-500' : 'border-border-cb'
                                }`}
                            />
                            {validationErrors.email && (
                                <p className="text-red-500 text-xs">{validationErrors.email}</p>
                            )}
                        </div>
                    </div>

                    <div className="flex-cb space-x-4-cb">
                        <div className="flex-1-cb">
                            <label className="block-cb text-sm-cb font-medium-cb">Phone Number *</label>
                            <input
                                type="tel"
                                name="phone"
                                value={clientDetails.phone}
                                onChange={handleChange}
                                className={`mt-1-cb block-cb w-full-cb border-cb p-2-cb ${
                                    validationErrors.phone ? 'border-red-500' : 'border-border-cb'
                                }`}
                            />
                            {validationErrors.phone && (
                                <p className="text-red-500 text-xs">{validationErrors.phone}</p>
                            )}
                        </div>
                    </div>

                    {/* <div className="flex-cb items-center-cb space-x-2-cb">
                        <input
                            type="checkbox"
                            name="smsReminder"
                            checked={clientDetails.smsReminder}
                            onChange={handleChange}
                            className="border-cb border-border-cb"
                        />
                        <label className="text-sm-cb margin-top">
                            I want to receive an SMS reminder 24 hours before this session
                        </label>
                    </div> */}

                    <div>
                        <label className="block-cb text-sm-cb font-medium-cb">Add Your Message</label>
                        <textarea
                            name="message"
                            value={clientDetails.message}
                            onChange={handleChange}
                            rows="4"
                            className="mt-1-cb block-cb w-full-cb border-cb border-border-cb p-2-cb"
                        />
                    </div>

                    <button
                        type="submit"
                        className="w-full-cb bg-primary-cb text-primary-foreground-cb hover:bg-primary/80-cb p-2-cb rounded-cb"
                    >
                        Request to Book
                    </button>
                </form>
            </div>

            <div className="p-6-cb bg-card-cb text-card-foreground-cb">
                <div className={`dropdown ${isDropdownActive ? 'active' : ''}`}>
                    <button className="dropdown-button-cb" onClick={toggleDropdown}>
                        Booking Details
                        <span className={`dropdown-arrow-cb ${isDropdownActive ? 'active' : ''}`}>
                            &#9662;
                        </span>
                    </button>
                    {isDropdownActive && (
                        <div className="dropdown-content-cb">
                            <div className="border-b-cb border-border-cb pb-4-cb mb-4-cb">
                                <h2 className="text-lg font-semibold">
                                    {schedule.day}, {new Date(schedule.date).toLocaleDateString()}
                                </h2>
                                <p>Time: {schedule.start_time} - {schedule.end_time} {schedule.timezone}</p>
                                <p>Service: {schedule.service.service_name}</p>
                                <p>Instructor: {schedule.instructor.name}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BookClasses;